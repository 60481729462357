import React, { memo } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';

export interface ActionsMenuProps {
  items?: MenuProps['items'];
}

const ActionsMenu = (props: ActionsMenuProps) => {
  const { items } = props;

  return (
    <Dropdown menu={{ items: items }} trigger={['click']}>
      <MoreOutlined style={{ fontSize: '1.5rem', cursor: 'pointer' }} />
    </Dropdown>
  );
};

export default memo(ActionsMenu);
