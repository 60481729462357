import React, { memo } from 'react';
import { MenuProps } from 'antd';

import ActionsMenu from 'components/Popover/ActionsMenu';
import { useAuthUser } from 'hooks';

const GraphCardMenu: React.FC<{ questionId: number; token: string; dataParams: any }> = ({
  questionId
}) => {
  const { email } = useAuthUser();

  const items: MenuProps['items'] = [
    ...(email?.endsWith('@perxtech.com')
      ? [
          {
            key: 'debug',
            label: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://metabase.perxtech.io/question/${questionId}`}
              >
                View Metabase Card
              </a>
            )
          }
        ]
      : [])
  ];

  return <ActionsMenu items={items} />;
};

export default memo(GraphCardMenu);
