import React from 'react';
import { MenuProps } from 'antd';

import ActionsMenu from 'components/Popover/ActionsMenu';
import { useAuthUser } from 'hooks';

interface GraphCardExtraProps {
  token?: string;
  dataParams?: object;
  helpText?: string;
  questionId?: number;
  dateRange?: any;
}

const GraphCardExtra = (props: GraphCardExtraProps) => {
  const { questionId } = props;

  const { email = '' } = useAuthUser();

  const items: MenuProps['items'] = [
    ...(email?.endsWith('@perxtech.com')
      ? [
          {
            key: 'debug',
            label: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://metabase.perxtech.io/question/${questionId}`}
              >
                Debug Metabase
              </a>
            )
          }
        ]
      : [])
  ];

  return <ActionsMenu items={items} />;
};

export default GraphCardExtra;
